<template>
  <div class="home">
    <div class="banner">
      <div class="banner__overlay"></div>
      <div class="banner__text container">
        <h1>SCOPOS</h1>
        <p>
          Somos uma empresa com mais de 10 anos de experiência em consultoria,
          treinamentos, documentações e equipamentos de proteção para o segmento
          de segurança do trabalho.
        </p>
        <a
          class="banner__button primary"
          href="https://api.whatsapp.com/send?phone=5511964091671"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
            />
          </svg>
          Contate-nos
        </a>
      </div>
      <div class="banner__shapes">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            style="fill: #fff"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
              c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
              c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="services">
      <div class="container">
        <h2>
          Nossos serviços
          <span class="divider" />
        </h2>

        <div class="services__box">
          <div
            class="services__item"
            v-for="service in services"
            :key="service.imgName"
          >
            <img
              :src="require(`../assets/images/` + service.imgName + `.png`)"
              alt="Treinamentos"
            />
            <div>
              <h3>{{ service.title }}</h3>
              <p>
                {{ service.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="training" class="training">
      <div class="training__overlay"></div>
      <div class="container">
        <h2>
          Treinamentos
          <span class="divider" />
        </h2>

        <p>
          A SCOPOS é especializada nos treinamentos de saúde e segurança de
          pessoas, tendo em vista o aperfeiçoamento, o atendimento as Normas
          Regulamentadoras entre outras legislações e a proliferação de uma
          cultura de segurança.
        </p>

        <p class="second-paragraph">
          Ainda que tenhamos este foco não nos contentamos com a simples
          exposição dos assuntos, sabendo que a forma é tão importante como
          conteúdo nossos instrutores levam consigo uma dinâmica e didática
          contemporânea e eficaz para o aprendizado.
        </p>

        <ul class="training__list">
          <li>
            <span>NR 5</span>
            MEMBROS DA CIPA
          </li>
          <li>
            <span>NR 10</span>
            SEGURANÇA EM INSTALAÇÕES E SERVIÇOS EM ELETRICIDADE
          </li>
          <li>
            <span>NR 11</span>
            SEGURANÇA NO TRANSPORTE, MOVIMENTAÇÃO E MANUSEIO DE MATERIAIS
          </li>
          <li>
            <span> NR 18 </span>
            SEGURANÇA NA INDÚSTRIA DA CONSTRUÇÃO CIVIL
          </li>
          <li>
            <span> NR 18 e NR 35 </span>
            SEGURANÇA NA MONTAGEM DE ANDAIMES
          </li>
          <li>
            <span> NR 18</span>
            PLATAFORMAS ELEVATÓRIAS
          </li>
          <li>
            <span>NR 20</span>SEGURANÇA NOS TRABALHOS COM INFLAMÁVEIS E
            COMBUSTÍVEIS
          </li>
          <li>
            <span>NR 32</span>
            SEGURANÇA NOS TRABALHO EM SERVIÇOS DE SAÚDE
          </li>
          <li>
            <span>NR 33</span>
            SEGURANÇA DO TRABALHO EM ESPAÇO CONFINADO (SUPERVISOR E VIGIA)
          </li>
          <li>
            <span>NR 35</span>
            SEGURANÇA EM TRABALHO EM ALTURA
          </li>
        </ul>
      </div>
    </div>

    <div id="reports" class="reports">
      <div class="reports__overlay"></div>
      <div class="container">
        <h2>
          Laudos
          <span class="divider" />
        </h2>

        <p>
          A SCOPOS entende que todos os Laudos, Programas e Documentos que as
          Normas Regulamentadores exigem precisam ser desenvolvidos com toda
          técnica e perícia para que seja confeccionados a contento da
          legislação pertinentes.
          <br />
          A escolha pela SCOPOS não é apenas uma questão de qualidade mas de
          segurança de ter sua empresa bem representada em situações sensíveis e
          legais.
        </p>

        <ul class="reports__list">
          <li>AVCB – Auto de vistoria do corpo de bombeiros</li>
          <li>PPRA – Programa de prevenção dos riscos ambientais</li>
          <li>LTCAT – Laudo técnico das condições ambientais do trabalho</li>
          <li>
            PCMAT – Programa de condições e meio ambiente de trabalho na
            indústria da construção
          </li>
          <li>Inventário NR12</li>
          <li>Prontuário de NR10</li>
          <li>Laudo termográfico</li>
          <li>Laudo SPDA - Para raios</li>
          <li>Laudo de insalubridade</li>
          <li>Laudo de periculosidade</li>
          <li>Laudo ergonômico</li>
        </ul>
      </div>
    </div>

    <div id="sipat" class="sipat">
      <div class="container">
        <h2>
          Eventos e SIPAT-MA
          <span class="divider" />
        </h2>

        <carousel
          navigationEnabled
          :paginationActiveColor="'#81061f'"
          :perPageCustom="[
            [768, 2],
            [1024, 3],
            [1920, 5],
          ]"
          class="sipat__carousel"
        >
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/palestra.png" alt="Palestra show" />
              <span> Palestra show </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/pecas.png" alt="Peças teatrais" />
              <span> Peças teatrais </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img
                src="../assets/images/teatro.png"
                alt="Intervenções teatrais"
              />
              <span> Intervenções teatrais </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/game_show.png" alt="Game show" />
              <span> Game show </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/talk_show.png" alt="Talk show" />
              <span> Talk show </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/parodia.png" alt="Paródias" />
              <span> Paródias </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/stand_up.png" alt="Stand up comedy" />
              <span> Stand up comedy </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img
                src="../assets/images/music.png"
                alt="Apresentações musicais"
              />
              <span> Apresentações musicais </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img
                src="../assets/images/bft.png"
                alt="Qualidade do produto e BPF"
              />
              <span> Qualidade do produto e BPF </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img
                src="../assets/images/quick_massage.png"
                alt="Quick massage"
              />
              <span> Quick massage </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/hipnose.png" alt="Hipnose" />
              <span> Hipnose </span>
            </div>
          </slide>
          <slide>
            <div class="sipat__item">
              <img src="../assets/images/magica.png" alt="Mágica com Caetano" />
              <span> Mágica com Caetano </span>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <div id="security" class="security">
      <div class="security__overlay"></div>
      <div class="container">
        <h2>
          Desenvolvimento de Sistemas de Segurança
          <span class="divider" />
        </h2>

        <div class="security__box">
          <div class="security__option">
            <h4>Sistema de votação Web</h4>
            <p>
              A SCOPOS é especializada nos treinamentos de saúde e segurança de
              pessoas, tendo em vista o aperfeiçoamento, o atendimento as Normas
              Regulamentadoras entre outras legislações e a proliferação de uma
              cultura de segurança.
            </p>

            <div class="security__features">
              <h5>FAÇA SUA VOTAÇÃO DA CIPA E OUTRAS ELEIÇÕES VIA WEB</h5>
              <ul>
                <li>Sistema totalmente WEB</li>
                <li>Página inteligente.</li>
                <li>Garantia de votação em 30 segundos.</li>
                <li>Administração completa do sistema.</li>
                <li>Importação do Cadastro dos dados de RH.</li>
                <li>Acesso por Computador e Celular.</li>
                <li>Extremo sigilo e proteção das informações.</li>
                <li>Emitimos a certificação de confidencialidade.</li>
                <li>Proteção dos dados dos votantes.</li>
              </ul>

              <p class="feature-description">
                Sistema testado e aprovado pela simplicidade, facilidade e
                inteligência. Ao final da votação emitimos o extrato dos
                resultados, com a descrição dos votados e votantes
              </p>
            </div>
          </div>

          <div class="security__option">
            <h4>Aplicativo personalizado</h4>
            <div class="custom-app">
              <p>
                A Segurança dos seus funcionário pode ser inteligente. Apresente
                para nós o tipo de controle ou desenvolvimento que precisa ter e
                desenvolveremos um sistema de segurança personalizado.
              </p>

              <img
                src="../assets/images/app_dev.svg"
                alt="Desenvolvimento de Aplicativos"
              />

              <p class="emphasis">
                Tenha um aplicativo personalizado para sua empresa
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => {
    return {
      services: [
        {
          imgName: "training",
          title: "Treinamentos",
          description: "Treinamento de saúde e segurança do trabalho",
        },
        {
          imgName: "docs",
          title: "Documentações",
          description: "Laudos, programas e alvarás",
        },
        {
          imgName: "protection",
          title: "Proteção",
          description: "Equipamentos de proteção coletiva",
        },
        {
          imgName: "sipat",
          title: "SIPAT MA",
          description: "Organização de SIPAT e Eventos de Segurança",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.banner {
  min-height: 926px;
  background-image: url("../assets/images/banner.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1024px) {
    min-height: 568px;
  }

  &__overlay {
    background-color: transparent;
    background-image: linear-gradient(90deg, #000 10%, #e73c7e);
    opacity: 0.7;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
  }

  &__text {
    z-index: 1;
  }

  &__shapes {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    line-height: 0;
    direction: ltr;
    transform: rotate(180deg);
  }

  h1 {
    color: #ffffff;
    font-family: "Poppins", Sans-serif;
    font-size: 75px;
    font-weight: 700;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: 55px;
    }
  }

  p {
    text-align: left;
    color: #ffffff;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    margin: 0;
    font-weight: 500;

    @media (max-width: 1024px) {
      font-size: 16px;
      margin: 20px 0;
    }
  }

  &__button {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 210px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #81061f;

      svg {
        fill: #fff;
      }
    }

    svg {
      margin-right: 8px;
      width: 28px;
      height: 28px;
      transition: 0.3s;
    }
  }
}

.services {
  margin-top: 70px;

  &__box {
    padding: 4em 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -25px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 25px;
    padding: 25px 0;
    min-width: 40%;

    @media (min-width: 769px) {
      flex: 1 1 0;
    }

    img {
      margin-right: 25px;
      width: 70px;
    }

    h3 {
      font-weight: 500;
      margin: 0;
      font-family: "Poppins";
      text-transform: uppercase;
      font-size: 20px;
      color: #81061f;
      letter-spacing: 1px;
    }

    p {
      margin: 0;
      color: #2b2b2b;
      letter-spacing: 1px;
      font-weight: 300;
      font-family: "Poppins";
    }
  }

  h2 {
    color: #81061f;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    line-height: 50px;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
}

.training {
  margin-top: 60px;
  padding: 60px 0;
  position: relative;

  &__overlay {
    background-color: #fafafa;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
  }

  h2 {
    color: #81061f;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;
    line-height: 50px;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }

  p {
    color: #000;
    z-index: 1;
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 30px;
    font-weight: 400;
    font-family: "Poppins";

    @media (max-width: 1024px) {
      font-size: 15px;
    }

    &.second-paragraph {
      margin-top: 0;
    }
  }

  &__list {
    padding: 0;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px -7.5px 0;

    li {
      color: #333;
      font-weight: 400;
      font-family: "Poppins";
      font-size: 16px;
      border: 2px solid #81061f;
      margin: 7.5px;
      padding: 15px 30px;
      background: rgba(255, 255, 255, 1);
      width: 290px;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      text-transform: capitalize;

      @media (max-width: 1024px) {
        padding: 15px;
        font-size: 14px;
      }

      span {
        color: #81061f;
        font-weight: 700;
        font-size: 22px;

        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
    }
  }
}

.reports {
  padding: 60px 0;
  position: relative;
  background-image: url("../assets/images/banner_reports.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  &__overlay {
    background-color: transparent;
    background-image: linear-gradient(90deg, #000000 30%, #570415 100%);
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
  }

  &__list {
    padding: 0;
    z-index: 1;

    li {
      color: #fff;
      margin: 7.5px 0;
      font-weight: 500;
      letter-spacing: 1px;
      list-style: inside;
      font-size: 18px;
      font-family: "Poppins";

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }

  .divider {
    background-color: #fff;
  }

  p {
    color: #fff;
    z-index: 1;
    font-size: 18px;
    letter-spacing: 2px;
    margin-top: 30px;
    font-weight: 400;
    font-family: "Poppins";

    @media (max-width: 1024px) {
      font-size: 15px;
    }

    &.second-paragraph {
      margin-top: 0;
    }
  }
}

.sipat {
  padding-top: 80px;
  position: relative;

  &__carousel {
    margin: 120px 0;

    .VueCarousel-pagination {
      position: absolute;
      bottom: -60px;
    }

    @media (max-width: 768px) {
      .VueCarousel-navigation-button {
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 150px;

      @media (max-width: 1024px) {
        width: 120px;
      }
    }

    span {
      margin-top: 24px;
      font-size: 18px;
      max-width: 90%;
      text-align: center;
      color: #333;
      font-weight: 500;
      font-family: "Poppins";
    }
  }

  h2 {
    color: #81061f;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
}

.security {
  padding: 60px 0;
  position: relative;
  background-color: #81061f;
  background-image: url("../assets/images/development.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &__overlay {
    background-color: transparent;
    background-image: linear-gradient(120deg, #111 50%, #81001f 100%);
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
  }

  &__box {
    display: flex;
    border: 2px solid #fff;
    align-items: stretch;
    margin-top: 45px;
    z-index: 1;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
    }
  }

  &__option {
    padding: 2em;
    flex-grow: 1;
    width: 100%;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 2em 10px;
    }

    &:first-child {
      background: #570415;
    }

    &:last-child {
      h4,
      span,
      p {
        color: #fff;
      }
    }

    h4 {
      font-size: 30px;
      color: #fff;
      font-family: "Poppins", Sans-serif;
      text-transform: uppercase;
      margin: 0;
      line-height: 32px;

      span {
        font-size: 14px;
        line-height: 15px;
        display: block;
        font-family: "Roboto", Sans-serif;
      }
    }

    img {
      width: 160px;
      margin: 40px auto;
      display: block;
    }

    .custom-app {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      p.emphasis {
        font-weight: 700;
        font-family: "Poppins";
        text-transform: uppercase;
        letter-spacing: 6px;
        text-align: right;

        @media (max-width: 768px) {
          margin-bottom: 50px;
          margin-top: 0;
        }
      }
    }
  }

  &__features {
    font-family: "Roboto";
    color: #fff;

    h5 {
      font-size: 18px;
      line-height: 22px;
      margin: 30px 0 20px;
    }

    p.feature-description {
      font-style: italic;
      font-weight: 500;
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin-bottom: 30px;

      li {
        list-style: inside;
        margin: 6px 0;
        font-size: 16px;
      }
    }
  }

  .divider {
    background-color: #fff;
    z-index: 1;
  }

  h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    margin-top: 18px;
    font-weight: 400;
    z-index: 1;
    letter-spacing: 1px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
}
</style>
