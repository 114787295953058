<template>
  <div class="protection">
    <div class="protection__banner">
      <div class="overlay"></div>
      <div class="protection__banner-text container">
        <h1>PROTEÇÃO</h1>
        <p>
          A SCOPOS fornece os EPC´s (equipamentos de proteção coletiva), que sua
          empresa precisa. Te atendemos de forma personalizada, além de EPC´s já
          conhecidos, no conceito <i>"on demand"</i>, nós encontraremos a
          solução para um EPC apropriado para que a Segurança seja mantida.
        </p>
      </div>
      <div class="banner__shapes">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            style="fill: #fff"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
              c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
              c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="protection__description">
      <div class="container">
        <h2>
          Nossos equipamentos
          <span class="divider" />
        </h2>

        <p>
          Aqui estão alguns de nossos produtos... Nos diga qual a sua
          necessidade ou a sua dificuldade e juntos desenvolveremos uma solução!
        </p>

        <div class="protection__list">
          <h4>
            Sinalização de Emergência
            <span> (Conforme ABNT NBR 13434-2): </span>
          </h4>
          <ul>
            <li>
              <span class="check"></span>
              Placas
            </li>
            <li>
              <span class="check"></span>
              Cones
            </li>
            <li>
              <span class="check"></span>
              Correntes
            </li>
            <li>
              <span class="check"></span>
              Faixa Zebrada
            </li>
          </ul>

          <h4>Equipamentos para Trabalhos com Eletricidade:</h4>
          <ul>
            <li>
              <span class="check"></span>
              Detector de Alta Tensão
            </li>
            <li>
              <span class="check"></span>
              Luva para alta Tensão
            </li>
            <li>
              <span class="check"></span>
              Vara de Manobra
            </li>
            <li>
              <span class="check"></span>
              Tapetes e Passadeiras para Alta Tensão
            </li>
          </ul>

          <h4>Equipamento para Primeiros Socorros:</h4>
          <ul>
            <li>
              <span class="check"></span>
              Pranchas de Resgate
            </li>
            <li>
              <span class="check"></span>
              Bolsa de Resgate
            </li>
            <li>
              <span class="check"></span>
              Cadeira de Rodas
            </li>
          </ul>

          <h4>Ergonomia</h4>

          <ul>
            <li>
              <span class="check"></span>
              Tapetes e Passadeiras ergonômicas
            </li>
          </ul>
        </div>
      </div>
    </div>

    <Galery />

    <div id="fire" class="protection__fire">
      <div class="container">
        <h2>
          Proteção Contra Incêndio
          <span class="divider" />
        </h2>
        <div class="protection__list">
          <ul>
            <li>
              <span class="check"></span>
              Dimensionamento de rota de fuga e equipamentos de emergência
            </li>
            <li>
              <span class="check"></span>
              Serviços com equipamentos
            </li>
            <li>
              <span class="check"></span>
              Fornecimento, Recarga de extintores e acessórios
            </li>
            <li>
              <span class="check"></span>
              Testes hidrostáticos em cilindros e mangueiras
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Galery from "@/components/common/Galery";

export default {
  components: {
    Galery,
  },
};
</script>
<style lang="scss">
.protection {
  min-height: 100vh;

  &__banner {
    min-height: 926px;
    background-image: url("../assets/images/protection.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1024px) {
      min-height: 650px;
    }

    .overlay {
      background-color: #fafafa;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 0;
      background: linear-gradient(-45deg, #000, #81061f, #000, #570415);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
      opacity: 0.6;
    }
  }

  &__banner-text {
    z-index: 1;

    h1 {
      color: #ffffff;
      font-family: "Poppins", Sans-serif;
      font-size: 75px;
      font-weight: 700;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 45px;
      }
    }

    p {
      text-align: left;
      color: #ffffff;
      font-family: "Poppins", Sans-serif;
      font-size: 20px;
      margin: 0;
      font-weight: 500;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }

  &__list {
    h4 {
      color: #333;
      font-family: "Poppins", Sans-serif;
      font-size: 18px;
      font-weight: 600;
      margin: 14px 0;
      text-transform: uppercase;
      z-index: 1;
      line-height: 24px;

      span {
        font-family: "Roboto";
        font-size: 15px;
        font-weight: 300;
        text-transform: capitalize;
      }
    }

    ul {
      padding: 0;
      margin-bottom: 40px;

      li {
        margin: 10px 0px;
        color: #333;
        font-weight: 300;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
      }

      .check {
        display: inline-block;
        position: relative;
        margin-right: 14px;
        width: 24px;
        height: 24px;
        border: 1px solid #81061f;
        border-radius: 50%;
        min-width: 24px;

        &:before {
          content: "";
          position: absolute;
          transform: rotate(45deg);
          height: 12px;
          width: 6px;
          border-bottom: 4px solid #81061f;
          border-right: 4px solid #81061f;
          left: 0;
          right: 0;
          top: -4px;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &__description {
    margin-top: 120px;

    h2 {
      color: #81061f;
      font-family: "Poppins", Sans-serif;
      font-size: 45px;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
      z-index: 1;
      line-height: 50px;

      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }

    p {
      color: #000;
      z-index: 1;
      font-size: 18px;
      letter-spacing: 1px;
      margin-top: 30px;
      font-weight: 400;
      font-family: "Poppins";

      @media (max-width: 1024px) {
        font-size: 15px;
      }
    }
  }

  &__fire {
    margin-top: 100px;
    position: relative;
    padding: 60px 0;
    background-image: url("../assets/images/fire.svg");
    background-size: 250px;
    background-repeat: no-repeat;
    background-position: 95% 80%;
    background-color: #fafafa;

    @media (max-width: 1024px) {
      background-image: none;
    }

    h2 {
      color: #81061f;
      font-family: "Poppins", Sans-serif;
      font-size: 45px;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
      z-index: 1;
      line-height: 50px;

      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }

    p {
      color: #000;
      z-index: 1;
      font-size: 18px;
      letter-spacing: 1px;
      margin-top: 30px;
      font-weight: 400;
      font-family: "Poppins";

      @media (max-width: 1024px) {
        font-size: 15px;
      }
    }

    .protection__list {
      margin-top: 40px;
      z-index: 1;

      li {
        margin: 20px 0;
      }
    }
  }
}
</style>
