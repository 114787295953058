<template>
  <div class="header" :class="{ 'header--without-opacity': duringScroll }">
    <div class="container">
      <router-link class="header__logo" to="/" tag="a">
        <img
          src="../../assets/images/logo_scopos_branco_.png"
          alt="Logo SCOPOS"
        />
      </router-link>
      <ul class="header__list">
        <router-link to="/" tag="li"> Início </router-link>
        <router-link to="/#training" tag="li"> Treinamentos </router-link>
        <router-link to="/#reports" tag="li"> Laudos </router-link>
        <router-link to="/#sipat" tag="li"> SIPAT-MA </router-link>
        <router-link to="/#security" tag="li"> Sistemas </router-link>
        <router-link to="/equipamentos-protecao" tag="li">
          Proteção
        </router-link>
      </ul>

      <Slide width="350" right noOverlay>
        <router-link to="/" tag="a"> Início </router-link>
        <router-link to="/#training" tag="a"> Treinamentos </router-link>
        <router-link to="/#reports" tag="a"> Laudos </router-link>
        <router-link to="/#sipat" tag="a"> SIPAT-MA </router-link>
        <router-link to="/#security" tag="a"> Sistemas </router-link>
        <router-link to="/equipamentos-protecao" tag="a">
          Proteção
        </router-link>
      </Slide>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  name: "Header",
  components: {
    Slide,
  },
  data: () => {
    return {
      duringScroll: false,
    };
  },
  mounted() {
    this.handleScroll();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 64) {
        this.duringScroll = true;
      } else {
        this.duringScroll = false;
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  z-index: 999;
  position: fixed;
  background-color: rgba(87, 4, 20, 0.7);
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 10px 0;
  transition: all ease 0.3s;

  @media (min-width: 1025px) {
    padding: 0;
  }

  &--without-opacity {
    background-color: rgba(87, 4, 20, 1);
  }

  &__list {
    display: none;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @media (min-width: 1025px) {
      display: flex;
    }

    li,
    a {
      font-size: 16px;
      padding: 15px;
      text-transform: uppercase;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.75);
      background: transparent;
      text-decoration: none;
      font-family: "Poppins";
      border-radius: 0;
      cursor: pointer;

      &:hover {
        color: #75051b;
        background: #fafafa;
      }
    }
  }

  &__logo {
    background-color: transparent;
    padding: 0;
    display: flex;

    img {
      width: 36px;
      padding: 5px;
      padding-left: 0;
    }
  }

  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  //Burguer Menu
  .bm-menu {
    background-color: #81061f;

    @media (max-width: 768px) {
      max-width: 100% !important;
    }
  }

  .bm-item-list {
    padding: 0 15px;
    margin: 0;
  }

  .bm-item-list a {
    font-size: 16px;
    padding: 15px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.75);
    background: transparent;
    text-decoration: none;
    font-family: "Poppins";
    border-radius: 0;
    cursor: pointer;

    &:hover {
      color: #75051b;
      background: #fafafa;
    }
  }

  .bm-burger-button {
    position: relative;
    left: 0;
    right: 0 !important;
    top: 0;
    bottom: 0;
  }

  .bm-burger-bars {
    background-color: #fafafa;
  }

  .line-style {
    height: 15%;
  }

  .bm-cross-button {
    left: 35px;
    right: inherit;
  }
}
</style>
