<template>
  <section class="galery">
    <div class="container">
      <h2>
        Galeria
        <span class="divider"></span>
      </h2>
      <carousel
        :perPageCustom="[
          [768, 1],
          [1024, 3],
          [1920, 5],
        ]"
        navigationEnabled
        :paginationActiveColor="'#81061f'"
        class="galery__carousel"
      >
        <slide class="item" v-for="(item, index) in pictures" :key="index">
          <img
            :src="require(`../../assets/images/` + item.path + `.png`)"
            :alt="item.alt"
            class="item-img"
          />
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => {
    return {
      pictures: [
        {
          path: "Equipa_Ergon_1",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_Ergon_2",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_PS_1",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_PS_2",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_Seg_1",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_Seg_2",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_Sinal_1",
          alt: "Equipamento Ergonômico",
        },
        {
          path: "Equipa_Sinal_2",
          alt: "Equipamento Ergonômico",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.galery {
  margin: 60px 0 90px;

  @media (max-width: 768px) {
    .VueCarousel-navigation-button {
      display: none;
    }
  }

  h2 {
    color: #81061f;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    z-index: 1;
    line-height: 50px;
  }

  &__carousel {
    padding: 60px 0 40px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .item-img {
      height: auto;
      border: 2px solid #81061f;
      border-radius: 50%;
      padding: 4px;

      @media (max-width: 768px) {
        height: 120px;
      }
    }

    .VueCarousel-pagination {
      position: absolute;
      bottom: -40px;
    }
  }
}
</style>
