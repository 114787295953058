<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("./assets/scss/patterns.scss");

#app {
  font-family: "Roboto", sans-serif;
  color: #777;
}

@media screen and (min-width: 1025px) {
  div[nooverlay] {
    display: none;
  }
}
</style>
